import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    this.formChanged = false;
    this.element.addEventListener("change", this.markChanged.bind(this));
  }

  markChanged(event) {
    if (!this.formChanged && event.target.name === "user[user_system_roles][]") {
      this.formChanged = true;
      this.submitButtonTarget.setAttribute("data-confirm", "Weet u zeker dat u de licentie van deze gebruiker wilt wijzigen?");
    }
  }
}
